import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainScreen from './components/MainScreen';
import * as Dashboard from './components/Dashboard';
import * as Preferences from './components/Preferences';
import * as Login from './components/Login';
import { useToken } from './useToken';

function App() {
    const {token, setToken} = useToken();

    if (!token || token.token === "") {
        return <Login.Login setToken={setToken}/>
    }
    return (
      <div className="AppCenter">
        <h1>Achievements</h1>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainScreen token={token} setToken={setToken} />} />
              <Route path="/dashboard" element={<Dashboard.Dashboard />} />
              <Route path="/preferences" element={<Preferences.Preferences />} />
              <Route path="/login" element={<Login.Login setToken={setToken} />} />
            </Routes>
          </BrowserRouter>
      </div>
    );
}

export default App;
