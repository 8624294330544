import { useState } from 'react';
import * as Types from './components/Types';

const achievemntsToken = "achievemntsToken";

export const useToken = () => {
    const getToken = (): Types.Token | undefined => {
        const tokenString = localStorage.getItem(achievemntsToken);
        const token = JSON.parse(tokenString ?? "{}");
        if (typeof token === "object") {
            return undefined;
        }
        return token;
    }
    const [token, setToken] = useState(getToken());

    const saveToken = (token: Types.Token) => {
        localStorage.setItem(achievemntsToken, JSON.stringify(token));
        setToken(token)
    }

    return {
        setToken: saveToken,
        token
    }
}
