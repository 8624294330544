/**
 * Formats the time to hh:mm.
 */
export const timeFormat = (d: Date): string => {
    let h = ""+d.getHours();
    let m = ""+d.getMinutes();
    if (d.getHours() < 10) {
        h = "0"+h;
    }
    if (d.getMinutes() < 10) {
        m = "0"+m;
    }
    return h + ":" + m
}

/**
 * Formats the date to yyyy-mm-dd.
 */
export const fullDateFormat = (d: Date): string => {
    const y = ""+d.getFullYear();
    let m = ""+(d.getMonth()+1); // getMonth() returns 0-11.
    let dd = ""+d.getDate();
    if (d.getMonth()+1 < 10) {
        m = "0"+m;
    }
    if (d.getDate() < 10) {
        dd = "0"+dd;
    }
    return y+"-"+m+"-"+dd
}

/**
 * Format the day to "Måndag 7 juni".
 */
export const dateFormat = (d: Date): string => {
    const day = dayName(d.getDay());
    const date = d.getDate();
    const month = monthName(d.getMonth());
    return day + " " + date + " " + month;
}

/**
 * Map Date.getDay to its name.
 */
export const dayName = (d: number): string => {
    const a = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
    if (d >= a.length || d < 0) {
        return "Unknown day: " + d;
    } else {
        return a[d];
    }
}

/**
 * Map Date.getMonth to its name.
 */
export const monthName = (m: number): string => {
    const a = ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"];
    if (m >= a.length || m < 0) {
        return "Unknown month: "+m;
    } else {
        return a[m];
    }
}

/**
 * Get the year as a string.
 */
export const fullYearFormat = (d: Date): string => {
    return ""+d.getFullYear();
}

/**
 * Get the current weeknumber.
 */
export const getWeek = (d: Date): number => {
    const onejan = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d.valueOf() - onejan.valueOf()) / 86400000) + onejan.getDay() + 1) / 7);
}

/**
 * Get the week as a string.
 */
export const weekFormat = (d: Date): string => {
    return ""+getWeek(d);
}

/**
 * Check if a date is in Daylight Saving Time.
 */
export const isDST = (d: Date): boolean => {
    const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();
}

/**
 * Returns the date for a given week in a given year.
 * This is according to ISO 8601 week.
 */
export const getDateOfISOWeek = (week: number, year: number): Date => {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}
