import React, { useState } from 'react';
import * as Types from './Types';

export const fetchAuth = (url: string, token: Types.Token, body: Object) => {
    return fetch(url, {
        method: "POST",
        headers: {"Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                 },
        body: JSON.stringify(body),
    })
}

interface Credentials {
    username: string;
    password: string;
}

const loginUser = async (credentials: Credentials) => {
    return fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
           })
           .then(data => {
               console.log("status: ", data?.status)
               if (data?.status === 200) {
                 return data;
               }
               console.log(data);
               throw(new Error("Failed"));
           })
           .then(data => data.json())
}

export interface LoginProps {
    setToken: any;
}

export const Login = (props: LoginProps) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        loginUser({
            username: username,
            password: password,
        })
        .then( (data) => {
            if (data?.token) {
                props.setToken(data.token)
            }
        })
        .catch( err => {
            console.log("Error auth", err);
            props.setToken("");
        })
    }
    return(
      <div>
        <h2>Log In</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <p>Username</p>
            <input type="text" onChange={ (e) => setUsername(e.target.value)} />
          </label>
          <label>
            <p>Password</p>
            <input type="password" onChange={ (e) => setPassword(e.target.value)} />
          </label>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    )
}
