import React from 'react';
import './MainScreen.css';
import * as Types from './Types';
import * as Time from './Time';
import { fetchAuth } from './Login';

interface MainScreenProps {
    token: Types.Token;
    setToken: (token: Types.Token) => void;
}

interface MainScreenState {
    achievements: Types.Achievement[];
    inputYear: number;
    inputWeek: number;
    inputAchievement: string;
}

class MainScreen extends React.Component<MainScreenProps> {
    state: MainScreenState = {
        achievements: [],
        inputYear: 2020,
        inputWeek: 1,
        inputAchievement: "",
    }
    componentDidMount() {
        const today = new Date();
        this.setState({
            inputYear: today.getFullYear(),
            inputWeek: Time.getWeek(today),
        });
        fetchAuth("/api/getAll", this.props.token, {})
            .then((data: any) => {
                if (data.status === 403) {
                    this.props.setToken({token: ""});
                    throw new Error("Unauthorized");
                } else if (data.status !== 200) {
                    throw new Error(`Error ${data.status}`);
                }
                return data;
            })
            .then((data: any) => data.json())
            .then((data: any) => {
                console.log("fetchAuth data: ", data)
                this.setState({achievements: data.achievements});
            })
            .catch((err: any) => {
                console.log("Error fetching data:", err);
            })
    }
    render() {
        const gridElements: JSX.Element[] = [];
        const lastWeek = [1970, 1];
        this.state.achievements.forEach( (a: Types.Achievement) => {
            //gridElements.push(<RenderYearWeek achievement={a} lastWeek={lastWeek}/>);
            gridElements.push(RenderYearWeek(a, lastWeek));
            gridElements.push(<RenderAchievement achievement={a}
                                                 deleteAchievement={this.deleteAchievement.bind(this)}
                                                 highlightAchievement={this.highlightAchievement.bind(this)} />);
            const aDate = new Date(a.date);
            lastWeek[0] = aDate.getFullYear();
            lastWeek[1] = Time.getWeek(aDate);
        })
        return(
            <div className="MainScreenGrid">
                <div className="MainScreenGridLeft MainScreenSpinners">
                    <input type="number"
                           id="inputYear"
                           value={this.state.inputYear}
                           onChange={(e) => this.setState({inputYear: e.target.value})}/>
                    w
                    <input type="number"
                           id="inputWeek"
                           min="0"
                           max="53"
                           value={this.state.inputWeek}
                           onChange={(e) => this.setState({inputWeek: e.target.value})}/>
                </div>
                <div className="MainScreenGridRight MainScreenGridRightInput">
                    <input type="text"
                           value={this.state.inputAchievement}
                           onChange={(e) => this.setState({inputAchievement: e.target.value})}
                           onKeyPress={(e) => {if (e.key === "Enter") {this.addAchievement()}}}
                           />
                    <input type="button"
                           value="+"
                           title="Add achievement"
                           onClick={() => this.addAchievement()} />
                </div>
                {gridElements.map((ge: JSX.Element) => {
                    return ge
                })}
            </div>
        );
    }
    addAchievement() {
        const weekDate = Time.getDateOfISOWeek(this.state.inputWeek, this.state.inputYear);
        const weekDateStr = Time.fullDateFormat(weekDate);
        const data = {
            "achievement": {
                "date": weekDateStr,
                "description": this.state.inputAchievement,
                "isHighlight": false,
            }
        }
        this.sendAchievement(data);
    }
    highlightAchievement(id: number, highlight: boolean) {
        const achievements = this.state.achievements
        const ix = achievements.findIndex((x: Types.Achievement) => { return x.id === id });
        if (ix >= 0) {
            let achievement = achievements[ix];
            achievement.isHighlight = highlight;
            const data = {
                "achievement": achievement,
            }
            this.sendAchievement(data);
        } else {
            console.log(`highlightAchievement: Did not find any achievement with id ${id}`);
        }
    }
    sendAchievement(body: any) {
        if (body?.achievement?.description === "") {
            return;
        }
        fetchAuth("/api/update", this.props.token, body)
            .then((data: any) => {
                if (data.status === 403) {
                    this.props.setToken({token: ""});
                    throw new Error("Unauthorized");
                } else if (data.status !== 200) {
                    throw new Error(`Error ${data.status}`);
                }
                return data;
            })
            .then((data: any) => data.json())
            .then((data: any) => {
                const achievements = this.state.achievements;
                const ix = achievements.findIndex((x: Types.Achievement) => { return x.id === data.id });
                if (ix >= 0) {
                    achievements.splice(ix, 1, data);
                } else {
                    achievements.push(data);
                }
                achievements.sort((a: Types.Achievement, b: Types.Achievement) => {
                    return b.date.localeCompare(a.date);
                });
                this.setState({
                    inputAchievement: "",
                    achievements
                });
            })
            .catch((err: any) => {
                console.log("Error sending data:", err);
            })
    }

    deleteAchievement(body: any) {
        if (body?.achievement?.id === "") {
            return;
        }
        fetchAuth("/api/delete", this.props.token, body)
            .then((data: any) => {
                if (data.status === 403) {
                    this.props.setToken({token: ""});
                    throw new Error("Unauthorized");
                } else if (data.status !== 200) {
                    throw new Error(`Error ${data.status}`);
                }
                return data;
            })
            .then((data: any) => data.json())
            .then((data: any) => {
                const achievements = this.state.achievements;
                const ix = achievements.findIndex((x: Types.Achievement) => { return x.id === data.id });
                if (ix >= 0) {
                    achievements.splice(ix, 1);
                }
                this.setState({
                    achievements
                });
            })
            .catch((err: any) => {
                console.log("Error deleting item:", err);
            })
    }
}

const RenderYearWeek = (achievement: Types.Achievement, lastWeek: number[]): JSX.Element => {
    const aDate = new Date(achievement.date);
    if (aDate.getFullYear() === lastWeek[0] && Time.getWeek(aDate) === lastWeek[1]) {
        return (
            <div className="MainScreenGridLeft">
            </div>
        )
    }
    return (
        <div className="MainScreenGridLeft">
            <div>{Time.fullYearFormat(new Date(achievement.date))}</div>
            <div>w{Time.weekFormat(new Date(achievement.date))}</div>
        </div>
    )
}

interface RenderAchievementProps {
    achievement: Types.Achievement;
    deleteAchievement: (body: any) => void;
    highlightAchievement: (id: number, highlight: boolean) => void;
}

const RenderAchievement = (props: RenderAchievementProps): JSX.Element => {
    const highLightCss = props.achievement.isHighlight ? "HighLightItem" : "";
    return (
        <div className={`MainScreenGridRight ${highLightCss}`}>
            {props.achievement.description}
            <RenderToolbox achievement={props.achievement}
                           deleteAchievement={props.deleteAchievement}
                           highlightAchievement={props.highlightAchievement} />
        </div>
    )
}

const RenderToolbox = (props: RenderAchievementProps): JSX.Element => {
    const bodyDelete = {
        "achievement": {
            "id": props.achievement.id,
        }
    }
    return (
        <div className="ItemToolbox">
            <input type="button"
                   className="ButtonItem ButtonRemoveItem"
                   value="Remove"
                   title="Remove achievement"
                   onClick={() => props.deleteAchievement(bodyDelete)} />
            <input type="button"
                   className="ButtonItem ButtonEditItem"
                   value="Edit"
                   title="Edit achievement"
                   onClick={() => alert("TODO")} />
            <input type="button"
                   className="ButtonItem ButtonHighlightItem"
                   value="Highlight"
                   title="Highlight achievement"
                   onClick={() => props.highlightAchievement(props.achievement.id, !props.achievement.isHighlight)} />
        </div>
    )
}

export default MainScreen;
